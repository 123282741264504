import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Platform, Image, Dimensions } from "react-native";
import SessionManager from "@presto-services/features/session/SessionManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import MessagesContext from "@presto-contexts/MessagesContext";
import { WalletComponent } from "@presto-screen-components/WalletCard/WalletCard";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import Config from "@presto-common/config";
import * as Linking from "expo-linking";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import { SafeAreaView } from "react-native-safe-area-context";
import UserModel from "@presto-component-models/UserModel";
import Pressable from "@presto-components/Pressable/Pressable";
import utils from "../../utils";
import AnalyticsManager from "../../common/AnalyticsManager";

const windowHeight = Dimensions.get("window").height;
const isDesktop = utils.isDesktop();

export function reducer() {
  return {};
}

export default function ProfileScreen({ navigation }) {
  const { images } = useAssets();
  const { locale } = useContext(MessagesContext);
  const { theme } = useContext(ThemeContext);
  const { user, setUser } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(locale);
  const userModel = new UserModel({ user: user });
  const showPoweredByPrestoIcon = false;

  const onLogout = () => {
    setLoading(true);
    SessionManager.logout(
      () => {
        AnalyticsManager.send("profile_logout");
        if (Platform.OS === "web" && Config.landingPage) {
          window.location.href = Config.landingPage;
        } else {
          setUser(null);
          Navigator.emit({
            event: "jumTab",
            params: {
              tabName: "HOME",
            },
          });
        }
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setLanguage(locale);
  }, [locale]);

  const onShowAddresses = () => {
    AnalyticsManager.send("profile_your_outlets");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "ADDRESSES_SCREEN",
        screenParams: {},
      },
    });
  };

  const onShowOrders = () => {
    AnalyticsManager.send("orders_list");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDERS_LIST_SCREEN",
        screenParams: {},
      },
    });
  };

  const onShowLoyaltyPoints = () => {
    AnalyticsManager.send("profile_transactions");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "LOYALTY_POINT_TRANSACTIONS_SCREEN",
        screenParams: {},
      },
    });
  };

  const onChooseLanguage = () => {
    AnalyticsManager.send("profile_choose_language");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "CHOOSE_LANGUAGE",
        screenParams: {},
      },
    });
  };

  const onReferFriend = () => {
    AnalyticsManager.send("profile_refer");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REFER_SCREEN",
        screenParams: {},
      },
    });
  };

  const navigateToTerms = () => {
    AnalyticsManager.send("terms_and_conditions");
    const termsUrl = Config.constants.terms_and_conditions;
    Linking.openURL(termsUrl);
  };

  const onRequestANewOutlet = () => {
    Linking.openURL(Config.constants.request_outlet_url);
  };

  const onRequestAProduct = () => {
    AnalyticsManager.send("profile_request_product");
    Linking.openURL(Config.constants.request_product_url);
  };

  const GotoPresto = () => {
    Platform.OS === "web"
      ? window.open(Config.constants.presto_website, "_blank")
      : Linking.openURL(Config.constants.presto_website);
  };

  const onClickWhatsapp = () => {
    let number = Config?.merchant_config?.customer_support_no
      .split("-")
      .join("");
    let url =
      Platform.OS == "web" && isDesktop
        ? `https://api.whatsapp.com/send?phone=${number.slice(
            1,
            number.length - 1
          )}`
        : `whatsapp://send?phone=${Config?.merchant_config?.customer_support_no}`;
    Linking.openURL(url).catch((e) => console.log(e.message));
  };
  if (!user) {
    return <View></View>;
  }

  const insets = useSafeArea();
  
  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={user?.name || ""}
          hideBack={true}
          titleProps={{
            size: 16,
            fontStyle: "semibold",
            color: userModel.isLoyaltyTierValid ? theme.white : theme.primary,
          }}
        />

        <ScrollView
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <WalletComponent onAction={onShowLoyaltyPoints} />
          <VerticalSpacing size={10} />
          <RowButton
            title={I18n.t("screen_messages.orders.past_orders")}
            showDisclosure={true}
            onPress={onShowOrders}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.outlets_title")}
            showDisclosure={true}
            onPress={onShowAddresses}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.products.request_product")}
            showDisclosure={true}
            onPress={onRequestAProduct}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.Refer_Your_Friends")}
            showDisclosure={true}
            onPress={onReferFriend}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <VerticalSpacing size={40} />
          <RowButton
            title={I18n.t("screen_messages.language.choose_a_language")}
            showDisclosure={true}
            onPress={onChooseLanguage}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

          <RowButton
            title={I18n.t("screen_messages.common.terms_and_conditions")}
            showDisclosure={true}
            onPress={navigateToTerms}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

          <RowButton
            title={I18n.t("screen_messages.contact.customer_support")}
            showDisclosure={true}
            titleIcon={
              <Image
                resizeMode="contain"
                source={images.whatsappIcon}
                style={{ width: 30, height: 30, marginLeft: 10 }}
              />
            }
            onPress={onClickWhatsapp}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

          <RowButton
            title={I18n.t("screen_messages.common.logout")}
            showDisclosure={true}
            onPress={onLogout}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <VerticalSpacing size={40} />

          {showPoweredByPrestoIcon ? (
            <>
              <Pressable onPress={() => GotoPresto()}>
                <Image
                  source={images.PoweredByPrestoIcon}
                  style={{
                    height: 20,
                    width: 130,
                    alignSelf: "center",
                    marginTop: 40,
                  }}
                  resizeMode="contain"
                />
              </Pressable>
            </>
          ) : null}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginVertical: 20,
            }}
          >
            <PrestoText size={theme.h6FontSize} color={theme.paragraph}>
              {I18n.t("screen_messages.app.version", {
                version: Config.version,
              })}{" "}
              ( {Config.expoRunTimeVersion} )
            </PrestoText>
          </View>
          <VerticalSpacing size={80} />
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
