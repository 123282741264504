import React from "react";
import { Image, View } from "react-native";
import { Card } from "@presto-components/Cards/WebCards";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import I18n from "i18n-js";
import Pressable from "@presto-components/Pressable/Pressable";

export default function SoldOutItemCard({
  product,
  onPress,
  onNotifyMe,
  priceAccessorMethod,
}) {
  const priceHandler = () => {
    return _.isFunction(priceAccessorMethod)
      ? priceAccessorMethod(product)
      : product.price;
  };

  return (
    <Card padding={10} key={product.productId}>
      <View
        style={{
          width: product.computedWidth,
          minHeight: 160,
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: product.picURL ? "70%" : "100%",
            }}
          >
            <View style={{ flex: 1 }}>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <PrestoText size={14} fontStyle="semibold">
                  {product.name}
                </PrestoText>
              </Pressable>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <PrestoText size={14}>{product.companyName}</PrestoText>
              </Pressable>
              <VerticalSpacing size={10} />
              <PrestoText size={14} fontStyle="semibold">
                {priceHandler()}
              </PrestoText>
            </View>

            <View style={{}}>
              <PrestoText
                fontStyle={{
                  fontSize: 10,
                  color: "#666666",
                }}
              >
                {product.molecule}
              </PrestoText>
            </View>
          </View>

          {product.picURL ? (
            <View style={{ width: 55, height: 55, marginRight: 10 }}>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <Image
                  source={{
                    uri: product.picURL,
                  }}
                  resizeMode="cover"
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 10,
                    overflow: "hidden",
                    borderWidth: 0.1,
                    borderColor: "#E3E3E3",
                  }}
                />
              </Pressable>
            </View>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {product.hasOffers ? (
            <PrestoFramedButton
              buttonStyle="primary"
              size="normal"
              width={120}
              title={I18n.t("screen_messages.count_offers", {
                count: product.offersCount,
              })}
              onPress={() => onPress(product.product, product.variation)}
            />
          ) : null}
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <PrestoText fontStyle="medium" size={12} color={"red"}>
              {I18n.t("screen_messages.sold_out_str")}
            </PrestoText>
            <VerticalSpacing size={3} />
            <View>
              {/* TODO(amal): Check if both product and variation are needed. */}
              <PrestoFramedButton
                buttonStyle="primary"
                size="normal"
                width={120}
                title={I18n.t("screen_messages.notify_me_str")}
                onPress={() => onNotifyMe(product.product, product.variation)}
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
              <VerticalSpacing size={15} />
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
}
