import React, { useContext, useState } from "react";
import { View, StyleSheet, FlatList, Pressable } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import I18n from "i18n-js";
import { useAssets, Components } from "presto-react-components";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import * as Linking from "expo-linking";
import config from "@presto-common/config";

export default function FAQSection({ onRedeem }) {
  const Table1 = Components.tables.Table1;
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const [openedItem, setOpenedItem] = useState(null);

  const gotoTermsAndConditions = () =>
    Linking.openURL(config.constants.terms_and_conditions);

  const faqs = [
    {
      title: I18n.t("screen_messages.faq.list1.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list1.description1"),
        I18n.t("screen_messages.faq.list1.description2"),
        I18n.t("screen_messages.faq.list1.description3"),
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list2.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list2.description1"),
        I18n.t("screen_messages.faq.list2.description2"),
        {
          type: "component",
          removePointer: true,
          component: (
            <Table1
              rows={[
                {
                  key: "tierName",
                  title: I18n.t("screen_messages.faq.loyalty_level"),
                  containerStyles: {
                    justifyContent: "flex-start",
                  },
                },
                {
                  key: "pointsEarned",
                  title: I18n.t("screen_messages.faq.points_earned_text"),
                },
              ]}
              columns={[
                {
                  tierName: I18n.t("screen_messages.faq.tiers.no_tier_text"),
                  pointsEarned: I18n.t(
                    "screen_messages.faq.tiers.no_tier_points_earned_text"
                  ),
                },
                {
                  tierName: I18n.t("screen_messages.faq.tiers.bronze_text"),
                  pointsEarned: I18n.t(
                    "screen_messages.faq.tiers.bronze_text_points_earned_text"
                  ),
                },
                {
                  tierName: I18n.t("screen_messages.faq.tiers.silver_text"),
                  pointsEarned: I18n.t(
                    "screen_messages.faq.tiers.silver_text_points_earned_text"
                  ),
                },
                {
                  tierName: I18n.t("screen_messages.faq.tiers.gold_text"),
                  pointsEarned: I18n.t(
                    "screen_messages.faq.tiers.gold_text_points_earned_text"
                  ),
                },
              ]}
            />
          ),
        },
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list3.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list3.description1"),
        {
          type: "link_text",
          part1: I18n.t("screen_messages.faq.list3.description3.part1"),
          part2: {
            type: "link",
            text: I18n.t("screen_messages.faq.list3.description3.part2"),
            onPress: gotoTermsAndConditions,
          },
          part3: I18n.t("screen_messages.faq.list3.description3.part3"),
        },
        {
          type: "link_text",
          part1: I18n.t("screen_messages.faq.list3.description4.part1"),
          part2: {
            type: "link",
            text: I18n.t("screen_messages.faq.list3.description4.part2"),
            onPress: onRedeem,
          },
          part3: I18n.t("screen_messages.faq.list3.description4.part3"),
        },
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list4.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list4.description1"),
        I18n.t("screen_messages.faq.list4.description2"),
        I18n.t("screen_messages.faq.list4.description3"),
        I18n.t("screen_messages.faq.list4.description4"),
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list6.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list6.description1"),
        I18n.t("screen_messages.faq.list6.description2"),
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list5.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list5.description1"),
        {
          title: I18n.t("screen_messages.faq.list5.description2"),
          descriptions: [
            I18n.t("screen_messages.faq.list5.description3.part1"),
            I18n.t("screen_messages.faq.list5.description3.part2"),
            I18n.t("screen_messages.faq.list5.description3.part3"),
          ],
        },
        I18n.t("screen_messages.faq.list5.description4"),
      ],
    },
    {
      title: I18n.t("screen_messages.faq.list7.title"),
      descriptions: [
        I18n.t("screen_messages.faq.list7.description1"),
      ],
    },
  ];

  function navigateTo(screenName) {}

  return (
    <View style={styles.container}>
      <View
        style={{
          paddingVertical: theme.primaryPadding,
          paddingHorizontal: theme.containerPadding,
        }}
      >
        <FlatList
          data={faqs}
          renderItem={({ item, index }) => {
            const isFirstIndex = index === 0;
            const isLastIndex = _.lastIndexOf(faqs) - 1 === index;
            const isOpened = openedItem?.index === index;
            return (
              <View key={index}>
                <View
                  style={{
                    borderBottomColor: theme.lightdark,
                    borderBottomWidth: isLastIndex ? 0 : 1,
                    padding: theme.primaryPadding,
                    ...(isFirstIndex && {
                      paddingTop: 0,
                    }),
                  }}
                >
                  <Pressable
                    style={[
                      styles.row,
                      {
                        justifyContent: "space-between",
                      },
                    ]}
                    onPress={() => {
                      setOpenedItem((prev) => {
                        let state = { ...item, index };
                        if (!prev) {
                          return state;
                        }
                        return prev.index !== index ? state : null;
                      });
                    }}
                  >
                    <View>
                      <PrestoText
                        fontStyle="400.normal"
                        size={14}
                        color="#757474"
                      >
                        {item.title}
                      </PrestoText>
                    </View>
                    <View>
                      <PrestoIcon
                        icon={
                          isOpened ? (
                            <View style={{ position: "relative", top: 5 }}>
                              <svgs.Minus
                                primaryColor="#757474"
                                width={15}
                                height={15}
                              />
                            </View>
                          ) : (
                            <svgs.PlusBoldIcon
                              width={15}
                              height={15}
                              color="#757474"
                            />
                          )
                        }
                      />
                    </View>
                  </Pressable>
                  {isOpened ? (
                    <View style={{ paddingTop: theme.primaryPadding }}>
                      {_.map(
                        item.descriptions,
                        (description, descriptionIndex) => {
                          const removePointer = _.get(
                            description,
                            "removePointer",
                            false
                          );
                          return (
                            <View
                              key={descriptionIndex}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                marginBottom: 10,
                              }}
                            >
                              {!removePointer ? (
                                <View>
                                  <PrestoText
                                    fontStyle="400.normal"
                                    color="#757474"
                                  >
                                    {"* "}
                                  </PrestoText>
                                </View>
                              ) : null}

                              <View
                                style={{
                                  width: "100%",
                                }}
                              >
                                {_.isString(description) ? (
                                  <PrestoText
                                    fontStyle="400.normal"
                                    color="#757474"
                                  >
                                    {description}
                                  </PrestoText>
                                ) : description.type === "link_text" ? (
                                  <PrestoText
                                    fontStyle="400.normal"
                                    color="#757474"
                                  >
                                    {description.part1}

                                    <Pressable
                                      onPress={description.part2.onPress}
                                      style={{ marginTop: 0 }}
                                    >
                                      <PrestoText
                                        fontStyle="400.normal"
                                        color={theme.primary}
                                      >
                                        {` ${description.part2.text} `}
                                      </PrestoText>
                                    </Pressable>

                                    <PrestoText
                                      fontStyle="400.normal"
                                      color="#757474"
                                    >
                                      {description.part3}
                                    </PrestoText>
                                  </PrestoText>
                                ) : description.title ? (
                                  <>
                                    <PrestoText
                                      fontStyle="400.normal"
                                      color="#757474"
                                      extraStyle={{ paddingBottom: 10 }}
                                    >
                                      {description.title}
                                    </PrestoText>
                                    {_.map(
                                      description.descriptions,
                                      (
                                        titleDescription,
                                        titleDescriptionIndex
                                      ) => {
                                        return (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "flex-start",
                                              alignItems: "flex-start",
                                              marginBottom: 5,
                                              paddingRight:
                                                theme.primaryPadding,
                                            }}
                                            key={titleDescriptionIndex}
                                          >
                                            <View>
                                              <PrestoText
                                                fontStyle="400.normal"
                                                color="#757474"
                                              >
                                                {" * "}
                                              </PrestoText>
                                            </View>
                                            <View style={{ width: "100%" }}>
                                              <PrestoText
                                                fontStyle="400.normal"
                                                color="#757474"
                                              >
                                                {titleDescription}
                                              </PrestoText>
                                            </View>
                                          </View>
                                        );
                                      }
                                    )}
                                  </>
                                ) : description.type === "component" ? (
                                  <View>{description.component}</View>
                                ) : null}
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                  ) : null}
                </View>
              </View>
            );
          }}
        />
      </View>
    </View>
  );
}
function getStyles(theme) {
  return StyleSheet.create({
    container: {},
    row: {
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "space-between",
    },
    heading: {
      color: theme.darkText,
    },
  });
}
