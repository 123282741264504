import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import LoadingContainer from "@presto-components/LoadingContainer";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import { WalletCheckoutComponent } from "@presto-screen-components/WalletCard/WalletCard";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import I18n from "i18n-js";
import _ from "lodash";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { Platform, ScrollView, View } from "react-native";
import { DotIndicator } from "react-native-indicators";
import { useSafeArea } from "react-native-safe-area-context";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import utils from "../../utils/index";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-community/async-storage";
import config from "@presto-common/config";
import { SafeAreaView } from "react-native-safe-area-context";
import AnalyticsManager from "@presto-common/AnalyticsManager";
import PaymentOptions from "@presto-screen-components/CartPayment/PaymentOptions";
import AppointmentManager from "@presto-services/features/appointment/AppointmentManager";

const PAYMENTS_OPTIONS = [
  {
    key: "COD",
    label: "screen_messages.cash_on_delivery",
  },
  {
    key: "ONLINE",
    label: "screen_messages.pay_appypay",
  },
];

export default function CartPayment({ route }) {
  const { merchant_config, max_points_to_use } = config;
  const { theme } = useContext(ThemeContext);
  const { cart, placeOrder, checkoutCart, clearCart } = useContext(CartContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const { address } = route.params;
  const [wallet, setWallet] = useState(undefined);
  const { user } = useContext(UserContext);
  const [paymentOption, setPaymentOption] = useState("COD");
  const [yourStoreId, setYourStoreId] = useState("");
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);

  const onCheckboxClicked = (key) => {
    setPaymentOption(key);
  };

  useFocusEffect(
    useCallback(() => {
      if (merchant_config?.features?.get_store_id) {
        async function getStoreId() {
          const get_store_id = await AsyncStorage.getItem("zhc_store_id");
          setYourStoreId(get_store_id);
        }
        getStoreId();
      }
    }, [])
  );

  const makeWalletPayment = (order) => {
    let amount = ((cart.total_price)) * max_points_to_use;
    WalletManager.makePayment(
      { id: order.id, id_type: "order_id", ...(amount <= wallet?.current_balance && { amount: amount }) },
      (response) => {
        setLoading(false);
        clearCart({});
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "ORDER_SUCCESSFUL_SCREEN",
          },
        });
      },
      (error) => {
        compositePayment(order)
      }
    );
  };

  const compositePayment = (order) => {
    let params = {
      composite_payment: {
        merchant_id: order.merchant_id,
        source: "WEBSITE",
        targets: [
          {
            id: order?.id,
            type: "order",
          },
        ],
      },
    };

    AppointmentManager.createCompositePayment(
      params,
      (response) => {
        if (response.data) {
          WalletManager.makePayment(
            { id: response.data.id, id_type: "composite_payment_id", amount: wallet.current_balance },
            (response) => {
              setLoading(false);
              clearCart({});
              Navigator.emit({
                event: "replace",
                params: {
                  screenName: "ORDER_SUCCESSFUL_SCREEN",
                },
              });
              setLoading(false);
              clearCart({});
            },
            (error) => {
              alert(utils.errorMessage(error));
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        console.log("createCompositePayment error", error);
        setLoading(false);
      }
    );
  }

  const goToOnlinePayment = (order) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "ONLINE_PAYMENT_SCREEN",
        screenParams: {
          order: order,
          clearCart: clearCart,
        },
      },
    });
  };

  const makeOnlinePayment = async (order, cart) => {
    var params = {
      id_type: "order_id",
      id: cart.id,
      user: user,
      total_amount: cart.original_gross_amount,
      appypay: {
        transaction_type: "Order",
        transaction_id: cart?.id,
      },
    };

    const result = await PaymentManager.initiateAppyPayPayment(
      params,
      (resp) => {
        setLoading(false);
        console.log("response===>", resp);
        goToOnlinePayment(cart);
      },
      (error) => {
        setLoading(false);
        console.log("capture error -", error);
        alert(I18n.t("screen_messages.appypay_error"));
      }
    );
  };

  const onPlaceOrder = async (payment, order) => {
    console.log("order :>> ", order, payment);
    const [error, response] = await placeOrder({});
    if (error) {
      console.log("Error in place order : ", error);
      alert("Error in placing order");
    } else {
      if (payment === "WALLET") {
        makeWalletPayment(response.data);
      } else if (payment === "ONLINE") {
        makeOnlinePayment(order, response.data);
      } else if (payment === "COD_WALLET") {
        makeWalletPayment(response.data);
      } else {
        setLoading(false);
        clearCart({});
        Navigator.emit({
          event: "replace",
          params: {
            screenName: "ORDER_SUCCESSFUL_SCREEN",
          },
        });
      }
    }
  };

  useEffect(() => {
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
      },
      () => { }
    );
  }, [user]);

  const onCartCheckout = async (payment = paymentOption) => {
    AnalyticsManager.send("place_order");
    setLoading(true);
    const [error, response] = await checkoutCart({
      address: address,
      instructions: "",
      payment_type: payment == "WALLET" ? "COD" : payment,
      delivery_type: "Delivery",
      other_data: {},
      source: "WEBSITE",
      instructions: yourStoreId,
    });

    if (error) {
      console.log(
        "Error in checking out ",
        error,
        JSON.stringify({
          address: address,
          instructions: "",
          payment_type: payment === "WALLET" ? "ONLINE" : payment,
          delivery_type: "Delivery",
          other_data: {},
          source: "WEBSITE",
          instructions: yourStoreId,
        })
      );
      alert("Error in checking out");
    } else {
      onPlaceOrder(payment == "WALLET" ? "COD_WALLET" : payment, response.data);
    }
  };
  let savings = 0;
  let freeItemAmount = 0;
  if (cart && cart.items) {
    _.forEach(cart.items, (product) => {
      if (product?.other_data?.variation_free_quantity) {
        freeItemAmount +=
          (product?.quantity *
            parseInt(product?.other_data?.variation_free_quantity) *
            (product?.rate / 100)) /
          (parseInt(product?.other_data?.variation_quantity) +
            parseInt(product?.other_data?.variation_free_quantity));
      }
      const parsedOldPrice = parseFloat(product.other_data?.old_price);
      const oldPrice = isNaN(parsedOldPrice) ? 0 : parsedOldPrice;
      let oldTotal = 0;
      if (oldPrice) {
        oldTotal = oldPrice * product.quantity * 100;
        savings +=
          oldTotal - (product.rate * product.quantity - product.total_tax);
      }
    });
  }

  const getProductDiscount = () => {
    const val = Number(cart?.item_promotion_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const getTotalSavings = () => {
    const val = Number(cart?.aggregate_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const getCouponDiscount = () => {
    const val = Number(cart?.total_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const cartInfo = {
    [I18n.t(
      "screen_messages.cart.total_price"
    )]: utils.formattedCurrencyToRoundOff(
      cart?.original_gross_amount || cart?.sub_total_price || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
    ...(cart?.discounts?.length > 0
      ? {
        [I18n.t("screen_messages.coupon_discount")]: getCouponDiscount(),
      }
      : {}),
    [I18n.t("screen_messages.product_discount")]: getProductDiscount(),
    [I18n.t("screen_messages.total_savings")]: getTotalSavings(),
    [I18n.t("screen_messages.taxes_str")]: utils.formattedCurrencyToRoundOff(
      cart?.total_taxes || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
    [I18n.t("screen_messages.you_pay_str")]: utils.formattedCurrencyToRoundOff(
      cart?.total_price || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
  };

  const insets = useSafeArea();

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.payments.select_payment_method")}
        />
        <ScrollView
          contentContainerStyle={{ paddingTop: theme.primaryPadding }}
        >
          <FullWidthHorizontalPaddedBox>
            {cart ? (
              <>
                <PrestoText
                  size={theme.h6FontSize}
                  color={theme.tertiaryTextColor}
                >
                  {I18n.t("screen_messages.orders.details_title")}
                </PrestoText>
                <KeyValueList dict={cartInfo} />
                <VerticalSpacing size={30} />
              </>
            ) : null}
          </FullWidthHorizontalPaddedBox>
          {/*  ====== Clint request to Hide for Now ===== */}
          {/* {wallet ? (
          <WalletCheckoutComponent
            showAction={wallet.current_balance > (cart?.total_price ?? 0)}
            actionTitle={I18n.t("screen_messages.offers.redeem_all")}
            onAction={() => onCartCheckout("WALLET")}
          />
        ) : null} */}
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
              height: 40,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PrestoText fontStyle={"semibold"} color={theme.primary} size={16}>
              {I18n.t("screen_messages.payments.payment_options_text")}
            </PrestoText>
          </View>
          {/* {PAYMENTS_OPTIONS.map((option) => (
          <>
            <CheckboxRowButton
              title={I18n.t(option.label)}
              showDisclosure={true}
              selected={option.key === paymentOption}
              onPress={() => onCheckboxClicked(option.key)}
            />
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          </>
        ))} */}

          <PaymentOptions
            address={address}
            paymentOption={paymentOption}
            onCheckboxClicked={onCheckboxClicked}
            wallet={wallet}
            cart={cart}
          />
          <View
            style={{
              width: "100%",
              height: 60,
              marginTop: 20,
              // position: "absolute",
              bottom: 0,
              left: 0,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              {loading ? (
                <PrestoSolidButton
                  size={"large"}
                  buttonStyle="primary"
                  title=""
                  leftIcon={
                    <DotIndicator
                      color={"white"}
                      maxScale={1.2}
                      minScale={0.2}
                      size={8}
                    />
                  }
                  // width={145}
                  onPress={() => { }}
                />
              ) : (
                <View style={{ marginTop: 20 }}>
                  <PrestoSolidButton
                    size="large"
                    title={I18n.t("screen_messages.orders.place_order")}
                    buttonStyle="primary"
                    titleColor={theme.white}
                    titleExtraStyle={{ textTransform: "uppercase" }}
                    onPress={() => onCartCheckout()}
                  />
                </View>
              )}
            </FullWidthHorizontalPaddedBox>
          </View>
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
