import React, { useState, useContext } from "react";
import { View, ScrollView, FlatList, Platform, Dimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";

import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";
import utils from "../../utils";
import config from "@presto-common/config";

export default function RedeemPointsScreen(props) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(true);
  const currencyDecimalPoint = _.get(
    config,
    "merchant_config.giftCurrencyDecimalPoint",
    "."
  );

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const styles = getStyles(theme, windowWidth);
  const { category_items, wallet } = props.route.params;

  const renderItem = ({ item, index }) => {
    return (
      <ItemCard
        key={item._source.id}
        item={item._source}
        wallet={wallet}
        priceAccessorMethod={(itemCardModel) => {
          let price = Number(itemCardModel.priceWithoutCurrency);
          if (isNaN(price)) {
            price = 0;
          }
          return itemCardModel.formatCurrency1;
        }}
      />
    );
  };

  return (
    <LoadingContainer loading={false}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t(
            "screen_messages.redeem_points_screen.redeem_zip_points"
          )}
        />
        <ScrollView
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{
            width: "100%",
            paddingBottom: 100,
            paddingTop: theme.containerPadding,
          }}
        >
          <FlatList
            data={category_items}
            renderItem={renderItem}
            keyExtractor={(item) => item._source.id}
            style={{ paddingTop: 5, paddingBottom: 150 }}
          />
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}

function getStyles(theme, windowWidth) {
  let minusWidth = theme.primaryPadding / 2;
  return {
    noItems: {
      fontSize: 14,
      fontWeight: "400",
      paddingTop: 15,
      paddingHorizontal: theme.containerPadding,
      color: theme.secondary,
    },
    container: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#EDEDED",
      borderRadius: 30,
    },
    buttonContainer: {
      paddingVertical: 10,
      height: 40,
      width: windowWidth / 3 - minusWidth,
      maxWidth: 400 / 3 - minusWidth,
      alignItems: "center",
      borderRadius: 30,
    },
  };
}
