import config from "@presto-common/config";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import LoadingContainer from "@presto-components/LoadingContainer";
import {
  PrestoTextButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import PasswordInput from "@presto-components/TextInputs/PasswordInput";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import LoginObject from "@presto-services/data_models/LoginObject";
import SessionManager from "@presto-services/features/session/SessionManager";
import * as Linking from "expo-linking";
import I18n from "i18n-js";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useFocusEffect } from "@react-navigation/native";
import AnalyticsManager from "../../common/AnalyticsManager";

import {
  Dimensions,
  Image,
  Keyboard,
  Platform,
  ScrollView,
  View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import asyncify from "../../common/Asyncify";
import utils from "../../utils/index";
const AsyncSessionManager = asyncify(SessionManager);
import { useAssets } from "presto-react-components";
import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";

export default function LoginWithPassword() {
  const { merchant_config } = config;
  const { images: Images, svgs } = useAssets();
  const windowHeight = Dimensions.get("window").height;
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const { theme } = useContext(ThemeContext);
  const { setUser, user } = useContext(UserContext);
  const { setPaneState } = useContext(RightPaneContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  useEffect(() => {
    setShown(true);
  }, []);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);
  const [padding, setPadding] = useState(0);
  const scrollRef = useRef();

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onPasswordChange = ({ nativeEvent: { text } }) => {
    setPassword(text);
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);

  const onLogin = () => {
    setLoading(true);
    async function login() {
      let lobj = new LoginObject();
      lobj.user = {
        login: phoneNumber,
        password: password,
      };
      const [error, response] = await AsyncSessionManager.login(lobj);
      AnalyticsManager.send("login_user");
      if (error) {
        alert(utils.errorMessage(error));
      } else {
        if (isDesktop) {
          setPaneState(false);
          WebNavigator.emit({
            event: "replace",
            params: {
              screenName: "HOME",
              screenParams: {
                is_new: "true",
              },
            },
          });
        }
        setUser(response.data);
        setShown(true);
        if (!isDesktop && Platform.OS === "web") {
          Linking.openURL("/home");
        }
      }
      setLoading(false);
    }
    login();
  };
  const onPressReadMore = () => {
    AnalyticsManager.send("about_zip");
    Linking.openURL(config.constants.read_more_about_product);
  };
  const onPressSignUp = () => {
    AnalyticsManager.send("register_user");
    if (isDesktop) {
      WebNavigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          screenParams: {
            title: "Sign Up",
            shouldGoBack: true,
            goBack: goBack,
          },
        },
      });
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          screenParams: {
            title: "Sign Up",
            shouldGoBack: true,
            goBack: goBack,
          },
        },
      });
    }
  };

  const onPressSignUpOTP = () => {
    AnalyticsManager.send("reset_password");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "LOGIN_WITH_OTP_SCREEN",
        screenParams: {
          title: "Sign Up",
          shouldGoBack: true,
          goBack: goBack,
        },
      },
    });
  };

  const eyeOnPress = () => {
    setHide(!hide);
  };

  useFocusEffect(
    useCallback(() => {
      if (merchant_config?.features?.get_store_id) {
        async function getStoreId() {
          var get_store_id = await AsyncStorage.getItem("zhc_store_id");
          console.log("Store_id==>", get_store_id);
          if (get_store_id == null) {
            Navigator.emit({
              event: "modal",
              params: {
                screenName: "GET_STORE_ID",
                screenParams: {},
              },
            });
          }
        }
        getStoreId();
      }
    }, [])
  );

  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1 }}
      loading={loading}
    >
      <ScrollView
        ref={scrollRef}
        enableOnAndroid={true}
        scrollEnabled={true}
        style={{ backgroundColor: theme.primary, flex: 1 }}
        contentContainerStyle={{
          paddingTop: 40,
          minHeight: windowHeight,
        }}
      >
        {shown ? (
          <View
            style={{
              flex: 1,
              flexDirextion: "column",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: padding,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              <Image
                source={Images.logo_transparent}
                style={{ width: 300, height: 216, alignSelf: "center" }}
              />
              <PrestoText
                size={24}
                fontStyle="600.bold"
                color={theme.white}
                extraStyle={{
                  textAlign: "center",
                  marginBottom: 10,
                  marginTop: -10,
                }}
              >
                {_.upperCase(I18n.t("screen_messages.login.text_below_logo"))}
              </PrestoText>

              <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                <PrestoText size={theme.h5FontSize} color={theme.white}>
                  {I18n.t("screen_messages.login.title")}
                </PrestoText>
                <VerticalSpacing size={15} />
              </FullWidthHorizontalPaddedBox>
              <VerticalSpacing size={15} />
              <PrestoTextInput
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.login.enter_your_phone_number"
                )}
                value={phoneNumber}
                keyboardType={"phone-pad"}
                onChange={onPhoneNumberChange}
                backgroundColor={theme.white}
                textContentType={"username"}
                autoCompleteType={"username"}
                importantForAutofill={"yes"}
                style={{ textInputHeight: 40 }}
              />
              <VerticalSpacing size={10} />
              <PasswordInput
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.login.enter_your_password"
                )}
                value={password}
                onChange={onPasswordChange}
                backgroundColor={theme.white}
                textContentType={"password"}
                autoCompleteType={"password"}
                importantForAutofill={"yes"}
                secureTextEntry={hide}
                icon={Images.eye}
                onPress={() => eyeOnPress()}
              />
              <VerticalSpacing size={15} />
              <PrestoFramedButton
                buttonStyle="primary"
                size="large"
                title={I18n.t("screen_messages.login.login_btn_name")}
                borderRadius={20}
                titleColor={theme.white}
                borderColor={theme.white}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={onLogin}
              />
              <VerticalSpacing size={20} />
              <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                <PrestoText
                  size={theme.h5FontSize}
                  color={theme.white}
                  extraStyle={{ marginTop: 40, marginBottom: -10 }}
                >
                  {I18n.t("screen_messages.users.new_with_question_mark")}
                </PrestoText>
              </FullWidthHorizontalPaddedBox>
              <VerticalSpacing size={20} />
              <PrestoFramedButton
                buttonStyle="primary"
                size="large"
                titleColor={theme.white}
                borderColor={theme.white}
                title={I18n.t("screen_messages.register.register_btn")}
                borderRadius={20}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={() => onPressSignUp()}
              />

              <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                <PrestoText
                  size={theme.h5FontSize}
                  color={theme.white}
                  extraStyle={{ marginTop: 40, marginBottom: -10 }}
                >
                  {I18n.t("screen_messages.forgot_pass_str")}
                </PrestoText>
              </FullWidthHorizontalPaddedBox>
              <VerticalSpacing size={20} />
              <PrestoFramedButton
                buttonStyle="primary"
                size="large"
                titleColor={theme.white}
                borderColor={theme.white}
                title={I18n.t("screen_messages.reset_str")}
                borderRadius={20}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={() => onPressSignUpOTP()}
              />
            </FullWidthHorizontalPaddedBox>
            <VerticalSpacing size={20} />
            <View style={{ marginBottom: 40, paddingLeft: 5 }}>
              <PrestoTextButton
                buttonStyle="secondary"
                size="normal"
                title={I18n.t("screen_messages.login.read_more_about")}
                rightIcon={<svgs.RightArrow primaryColor={theme.white} />}
                titleColor={theme.white}
                onPress={() => onPressReadMore()}
              />
            </View>
          </View>
        ) : null}
      </ScrollView>
    </LoadingContainer>
  );
}
