import React, { useContext, useReducer, useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import asyncify from "../../common/Asyncify";
const AsyncSearchManager = asyncify(SearchManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import SearchableScreen from "@presto-screens/SearchableScreen/SearchableScreen";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import { useCatalog } from "@presto-stores/CatalogStore";
import AnalyticsManager from "../../common/AnalyticsManager";

const windowHeight = Dimensions.get("window").height;

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_TOP_CATEGORIES":
      return { ...state, top_level_categories: payload };

    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          [payload.cat_id]: payload.count,
        },
      };
    case "SET_BRANDS":
      return {
        ...state,
        brands: payload,
      };
    case "SET_COMPANIES":
      return {
        ...state,
        companies: payload,
      };
    case "SET_MOLECULES":
      return {
        ...state,
        molecules: payload,
      };
    case "SET_THERAPIES":
      return {
        ...state,
        therapies: payload,
      };
    case "SET_NEWITEMS":
      return {
        ...state,
        newItems: payload,
      };
    case "BEST_SELLERS":
      return {
        ...state,
        bestsellers: payload,
      };
  }
  return state;
}

export default function Browse({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [state, dispatch] = useReducer(reducer, { category_count: {} });
  const catalog = useCatalog((state) => state.catalog);

  useEffect(() => {
    SearchManager.searchCategory(
      { top_level: true },
      (response) => {
        dispatch({
          type: "SET_TOP_CATEGORIES",
          payload: response.hits.hits,
        });
      },
      (error) => { }
    );
  }, []);

  useEffect(() => {
    if (!catalog?.id) {
      return;
    }

    var params = {
      catalog_id: catalog?.id,
    };
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "company_name",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_company_name.buckets,
          "key"
        );
        dispatch({
          type: "SET_BRANDS",
          payload: sortedList,
        });
      },
      (error) => { }
    );

    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "distributor",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_distributor.buckets,
          "key"
        );
        dispatch({
          type: "SET_COMPANIES",
          payload: sortedList,
        });
      },
      (error) => { }
    );

    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "molecule",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_molecule.buckets,
          "key"
        );
        dispatch({
          type: "SET_MOLECULES",
          payload: sortedList,
        });
      },
      (error) => { }
    );

    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "therapy_area_simple",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_therapy_area_simple.buckets,
          "key"
        );
        dispatch({
          type: "SET_THERAPIES",
          payload: sortedList,
        });
      },
      (error) => { }
    );

    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "is_new",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_is_new.doc_count,
          "key"
        );
        dispatch({
          type: "SET_NEWITEMS",
          payload: sortedList,
        });
        console.log(
          "response.aggregations.===>",
          JSON.stringify(response.aggregations)
        );
      },
      (error) => { }
    );

    SearchManager.getZipHealthFilteredOtherDataAttributes(
      params,
      "bestsellers",
      (response) => {
        let sortedList = _.sortBy(
          response.aggregations.distinct_bestsellers.buckets,
          "key"
        );
        dispatch({
          type: "BEST_SELLERS",
          payload: sortedList,
        });
      },
      (error) => { }
    );
  }, [catalog?.id]);

  const fetchCount = async (categoryId) => {
    const [error, categoryResponse] = await AsyncSearchManager.searchCategory({
      category_id: categoryId,
    });
    if (error) {
      return;
    }

    if ((categoryResponse.hits?.hits?.length || 0) > 0) {
      const [
        error,
        response,
      ] = await AsyncSearchManager.fuzzySearchCategoryItem({
        category_ids: _.map(
          categoryResponse.hits.hits,
          (cat) => cat._source.id
        ),
      });
      if (!error) {
        dispatch({
          type: "SET_CATEGORY_COUNT",
          payload: { cat_id: categoryId, count: response.hits.total?.value || response.hits.total },
        });
      }
    } else {
      const [
        error,
        response,
      ] = await AsyncSearchManager.fuzzySearchCategoryItem({
        category_id: categoryId,
      });
      if (!error) {
        dispatch({
          type: "SET_CATEGORY_COUNT",
          payload: { cat_id: categoryId, count: response.hits.total?.value || response.hits.total },
        });
      }
    }
  };

  useEffect(() => {
    _.forEach(state.top_level_categories, (cat) => {
      fetchCount(cat._source.id);
    });
  }, [state.top_level_categories]);

  const onCategoryPress = (cat) => {
    AnalyticsManager.send("top_level_categories");
    SearchManager.searchCategory(
      { category_id: cat._id },
      (response) => {
        if (response.hits.hits.length === 0) {
          Navigator.emit({
            event: "push",
            params: {
              screenName: "SEARCH_RESULTS_SCREEN",
              screenParams: {
                searchParams: { category: cat._source, catalogId: catalog?.id },
              },
            },
          });
        } else {
          Navigator.emit({
            event: "push",
            params: {
              screenName: "SUB_CATEGORIES_BROWSE_SCREEN",
              screenParams: { category: cat._source, catalogId: catalog?.id },
            },
          });
        }
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  };

  const onBrowseItemPressed = (keyword) => {
    let searchParams = { is_new: true };
    if (keyword === "bestsellers") {
      searchParams = { bestsellers: true };
    }
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: searchParams, catalogId: catalog?.id },
      },
    });
  };

  const onKeywordPress = (key, values, name) => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "KEYWORD_BROWSE_SCREEN",
        screenParams: {
          key: key,
          values: values,
          name: name,
          catalogId: catalog?.id,
        },
      },
    });
  };

  const onSearchFocus = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: {}, focus: true, catalogId: catalog?.id },
      },
    });
  };
  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      showSoftInputOnFocus={false}
      placeholder={I18n.t(
        "screen_messages.common.search_by_placeholder_options.category"
      )}
    >
      <View>
        <ScrollView
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{ paddingBottom: 150 }}
        >
          <View>
            {_.map(state.top_level_categories, (cat) => {
              return (
                <View key={cat._source.id}>
                  <RowButton
                    title={cat._source.name}
                    rightTitle={state?.category_count?.[cat._source.id] ?? ""}
                    onPress={() => {
                      onCategoryPress(cat);
                    }}
                    showDisclosure={true}
                  />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </View>
              );
            })}
            <VerticalSpacing size={40} />
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <PrestoText
                size={theme.h5FontSize}
                fontStyle={"semibold"}
                color={theme.primary}
              >
                {I18n.t("screen_messages.common.browse_by")}
              </PrestoText>
            </View>
            {state.newItems && state.newItems > 0 ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.new_items_str")}
                  rightTitle={
                    state.newItems && state.newItems > 0 ? state.newItems : ""
                  }
                  onPress={() => onBrowseItemPressed("is_new")}
                  showDisclosure={true}
                />

                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {state.bestsellers && state.bestsellers.length > 0 ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.vendors.best_seller")}
                  rightTitle={
                    state.bestsellers && state.bestsellers.length > 0
                      ? state.bestsellers[0].doc_count
                      : ""
                  }
                  onPress={() => onBrowseItemPressed("bestsellers")}
                  showDisclosure={true}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {state.brands && state.brands?.length > 0 ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.manufacturers")}
                  rightTitle={state.brands ? state.brands?.length : ""}
                  onPress={() => {
                    AnalyticsManager.send("browse_by_manufacturers");
                    onKeywordPress(
                      "company_name",
                      state.brands,
                      I18n.t("screen_messages.manufacturers")
                    );
                  }}
                  showDisclosure={true}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {state.companies && state.companies?.length ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.distributors_label")}
                  rightTitle={state.companies ? state.companies?.length : ""}
                  onPress={() => {
                    AnalyticsManager.send("browse_by_distributors");
                    onKeywordPress(
                      "distributor",
                      state.companies,
                      I18n.t("screen_messages.distributors_label")
                    );
                  }}
                  showDisclosure={true}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {state.molecules && state.molecules?.length > 0 ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.molecule.title")}
                  rightTitle={state.molecules ? state.molecules?.length : ""}
                  onPress={() => {
                    AnalyticsManager.send("browse_by_molecule");
                    onKeywordPress(
                      "molecule",
                      state.molecules,
                      I18n.t("screen_messages.molecule.title")
                    );
                  }}
                  showDisclosure={true}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
            {state.therapies && state.therapies?.length > 0 ? (
              <>
                <RowButton
                  title={I18n.t("screen_messages.therapy.title")}
                  rightTitle={state.therapies ? state.therapies?.length : ""}
                  onPress={() => {
                    AnalyticsManager.send("browse_by_therapy");
                    onKeywordPress(
                      "therapy_area_simple",
                      state.therapies,
                      I18n.t("screen_messages.therapy.title")
                    );
                  }}
                  showDisclosure={true}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            ) : null}
          </View>
          <VerticalSpacing size={30} />
          <DealsComponent />
        </ScrollView>
      </View>
    </SearchableScreen>
  );
}
